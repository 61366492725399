'use client';

import { Slide, SlideProps, useScrollTrigger } from '@mui/material';
import { useEffect } from 'react';
import { useNavbarActions } from '../NavBar/useNavbarState';

export type HideOnScrollProps = {
  children: React.ReactElement;
  in?: boolean;
} & SlideProps;

const HideOnScroll = ({ in: customIn, children, ...props }: HideOnScrollProps) => {
  const trigger = useScrollTrigger();
  const setNavBarPosition = useNavbarActions((s) => s.setNavBarPosition);

  useEffect(() => {
    setNavBarPosition(trigger);
  }, [trigger, setNavBarPosition]);

  return (
    <Slide
      appear={false}
      direction="down"
      in={customIn ?? !trigger}
      timeout={{
        enter: 175,
        exit: 175,
      }}
      easing={{ enter: 'linear', exit: 'linear' }}
      {...props}>
      {children}
    </Slide>
  );
};

export default HideOnScroll;
