import { SOCIAL_LINKS_FRAGMENT } from '@/gql/fragments/common.gql';
import { createFragmentArrayParser } from '@liquorice/gql-utils';
import { firstNonNullable, toStringOrNull } from '@liquorice/utils';

export type SocialLinks = {
  facebook?: string | null;
  twitter?: string | null;
  instagram?: string | null;
};

export const parseSocialLinksFragment = createFragmentArrayParser(
  SOCIAL_LINKS_FRAGMENT,
  (data): SocialLinks => {
    const res = firstNonNullable(data);
    return {
      facebook: toStringOrNull(res?.facebook),
      twitter: toStringOrNull(res?.twitter),
      instagram: toStringOrNull(res?.instagram),
    };
  }
);
