'use client';

import { NavMenuItem } from '@/lib/parsers/navigation';
import { fmtSplitOnCapital, fmtTitleCase } from '@/lib/utils/format';
import useScrollPosition from '@/lib/utils/useScrollPosition';
import { useBreakpointMax } from '@/theme';
import { isTypedFragment } from '@liquorice/gql-utils';
import { toString } from '@liquorice/utils';
import { AnimatePresence, motion } from 'framer-motion';
import Logo from '../Logo';
import NavDrawer from '../NavDrawer';
import Box from '../ui/Box';
import Divider from '../ui/Divider';
import Flex from '../ui/Flex';
import * as navBarMobileStyles from './NavBar.Mobile.css';
import NavIcons from './NavIcons';
import NavInner from './NavInner';
import NavMenu from './NavMenu';
import NavSearch from './NavSearch';
import NavShim from './NavShim';
import { useNavBar } from './useNavBar';

const NavBarMobile = () => {
  const sm = useBreakpointMax('sm');
  const pos = useScrollPosition();

  const { profile, shoppingBasket, page } = useNavBar();
  const showExtras = isTypedFragment(page, 'event_Entry') ? pos < 50 : false;

  // Create extras for the nav drawer
  const navExtras: NavMenuItem[] = [
    {
      id: toString(profile?.id),
      title: fmtTitleCase(fmtSplitOnCapital(toString(profile?.handle))),
      href: profile?.externalUrl,
      type: 'link',
      external: true,
    },
    {
      id: toString(shoppingBasket?.id),
      title: fmtTitleCase(fmtSplitOnCapital(toString(shoppingBasket?.handle))),
      href: shoppingBasket?.externalUrl,
      type: 'link',
      external: true,
    },
  ];

  return (
    sm && (
      <Box as="nav">
        <NavShim />
        <NavInner ContainerProps={{ disablePadding: true }}>
          <Flex direction="row" justifyContent="between" cx={{ pX: 'xs' }}>
            <Box className={navBarMobileStyles.logoWrapper}>
              <Logo homeLink SvgProps={{ viewBox: '0 0 82 64' }} />
            </Box>
            <NavIcons columnGap="3xs" btnSize="large" menu shoppingBasket={false} profile={false} />
          </Flex>
          <NavDrawer combineItems items={navExtras} />
          <NavSearch />
          <AnimatePresence>
            {!showExtras && (
              <Box
                as={motion.div}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>
                <Divider cx={{ color: 'primaryTintLight', width: 'full' }} />
                <NavMenu
                  cx={{ justifyContent: 'center', pX: 'xs', pT: '2xs', pB: '3xs' }}
                  BtnProps={{
                    color: 'primary',
                    size: 'small',
                    underline: 'hover',
                    weight: 'semibold',
                    disableTransition: true,
                    className: navBarMobileStyles.navMenu,
                  }}
                  columnGap="3xl"
                />
              </Box>
            )}
          </AnimatePresence>
        </NavInner>
      </Box>
    )
  );
};

export default NavBarMobile;
