'use client';

import { NavMenuItem } from '@/lib/parsers/navigation';
import { Drawer } from '@mui/material';
import IconButton from '../IconButton';
import { useNavBar } from '../NavBar/useNavBar';
import { useNavbarActions } from '../NavBar/useNavbarState';
import { NavDrawerItem } from '../NavDrawerItem/NavDrawerItem';
import UnstyledAccordion from '../UnstyledAccordion';
import Flex, { FlexProps } from '../ui/Flex';
import * as styles from './NavDrawer.css';

export type NavDrawer = FlexProps<
  'ul',
  {
    items?: NavMenuItem[];
    combineItems?: boolean;
  }
>;

export default function NavDrawer({ items, combineItems = false, ...props }: NavDrawer) {
  const { secondaryItems } = useNavBar();
  const navPosition = useNavbarActions((s) => s.navPosition);
  const setNavPosition = useNavbarActions((s) => s.setNavPosition);

  const toggleDrawer = (newPosition: boolean) => () => {
    setNavPosition(newPosition);
  };

  if (!secondaryItems) return null;

  const drawerItems = combineItems
    ? [...secondaryItems, ...(items ?? [])]
    : items || secondaryItems;

  return (
    <Drawer anchor="right" open={navPosition} onClose={toggleDrawer(false)}>
      <Flex as="ul" className={styles.inner} {...props}>
        <Flex cx={{ width: 'full', pX: 'sm', pY: 'sm' }} alignItems="end">
          <IconButton icon="close" size="tiny" color="primary" onClick={toggleDrawer(false)} />
        </Flex>
        <UnstyledAccordion>
          {drawerItems?.map((item, index) => (
            <NavDrawerItem key={item.id} {...{ item, index }} />
          ))}
        </UnstyledAccordion>
      </Flex>
    </Drawer>
  );
}
