'use client';

import { ENTRY_BASE_FRAGMENT } from '@/gql/fragments/entries.gql';
import { mergePropsClassName } from '@liquorice/utils';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { getFragmentData } from '__generated__';
import Shim, { ShimProps } from '../Shim';
import * as styles from './NavBar.css';
import { useNavBar } from './useNavBar';
import { useNavbarActions } from './useNavbarState';

export default function NavShim(props: ShimProps) {
  const { height, fallbackHeight } = useNavbarActions();

  const { page } = useNavBar();
  const base = getFragmentData(ENTRY_BASE_FRAGMENT, page);
  const sectionHandle = base?.sectionHandle;
  const eventUri = sectionHandle === 'event';

  return (
    <Shim
      relative={false}
      {...mergePropsClassName(props, styles.navShim({ overlay: eventUri }))}
      style={assignInlineVars({ [styles.navbarHeight]: `${height ?? fallbackHeight}px` })}
    />
  );
}
