import { create } from 'zustand';

export type NavBarResponsiveVariant = 'mobile' | 'desktop';

export type NavBarState = {
  responsiveVariant: NavBarResponsiveVariant;
  setResponsiveVariant: (responsiveVariant: NavBarResponsiveVariant) => void;

  fallbackHeight: number;
  height?: number | undefined;
  setHeight: (height: number | undefined) => void;

  search: boolean;
  setSearch: (search: boolean) => void;

  posColorSet: boolean;
  setPosColorSet: (posColorSet: boolean) => void;

  navPosition: boolean;
  setNavPosition: (navPosition: boolean) => void;

  navBarPosition: boolean;
  setNavBarPosition: (navBarPosition: boolean) => void;
};

export const useNavbarActions = create<NavBarState>()((set) => ({
  responsiveVariant: 'desktop',
  setResponsiveVariant: (responsiveVariant: NavBarResponsiveVariant) => set({ responsiveVariant }),

  fallbackHeight: 72,
  height: undefined,
  setHeight: (height?: number) => set(() => ({ height })),

  search: false,
  setSearch: (search?: boolean) => set(() => ({ search })),

  posColorSet: false,
  setPosColorSet: (posColorSet?: boolean) => set(() => ({ posColorSet })),

  navPosition: false,
  setNavPosition: (navPosition?: boolean) => set(() => ({ navPosition })),

  navBarPosition: true,
  setNavBarPosition: (navBarPosition?: boolean) => set(() => ({ navBarPosition })),
}));

export const useIsDesktop = () => useNavbarActions((s) => s.responsiveVariant === 'desktop');
