'use client';

import IconButton from '../IconButton';
import Flex, { FlexProps } from '../ui/Flex';
import * as styles from './NavBar.css';
import { useNavBar } from './useNavBar';
import { useNavbarActions } from './useNavbarState';

type NavIconsProps = FlexProps<
  'div',
  {
    shoppingBasket?: boolean;
    profile?: boolean;
    search?: boolean;
    menu?: boolean;
    // Error occuring with Typescript's Pick utility type
    btnSize?: 'normal' | 'small' | 'large' | 'tiny';
  }
>;

export default function NavIcons({
  shoppingBasket: showBasket = true,
  profile: showProfile = true,
  search: showSearch = true,
  menu: showMenu = false,
  btnSize = 'small',
  ...props
}: NavIconsProps) {
  const { shoppingBasket, profile } = useNavBar();

  const setSearch = useNavbarActions((s) => s.setSearch);
  const setNavPosition = useNavbarActions((s) => s.setNavPosition);
  const withColorSet = useNavbarActions((s) => s.posColorSet);
  const navIconClass = styles.navIcon({ withColorSet });

  return (
    <Flex direction="row" columnGap="sm" {...props}>
      {showBasket && (
        <IconButton
          icon="shoppingCart"
          href={shoppingBasket?.externalUrl ?? ''}
          disableTransition
          target="_blank"
          size={btnSize}
          className={navIconClass}
        />
      )}
      {showProfile && (
        <IconButton
          icon="profile"
          disableTransition
          href={profile?.externalUrl ?? ''}
          size={btnSize}
          target="_blank"
          className={navIconClass}
        />
      )}
      {showSearch && (
        <IconButton
          onClick={() => setSearch(true)}
          icon="search"
          size={btnSize}
          disableTransition
          className={navIconClass}
        />
      )}
      {showMenu && (
        <IconButton
          onClick={() => setNavPosition(true)}
          icon="menu"
          size={btnSize}
          disableTransition
          className={navIconClass}
        />
      )}
    </Flex>
  );
}
