import { IconName } from '../Icon';
import IconButton, { IconButtonProps } from '../IconButton';
import { useUnstyledAccordionItem } from './useUnstyledAccordion';

export type UnstyledAccordionItemCaretProps = {
  index: number;
  enableToggle?: boolean;
  openIcon?: IconName;
  openIconProps?: Omit<IconButtonProps, 'icon'>;
  closeIcon?: IconName;
  closeIconProps?: Omit<IconButtonProps, 'icon'>;
  disableCaret?: boolean;
};

const UnstyledAccordionItemCaret = ({
  index,
  enableToggle,
  openIcon = 'chevronDown',
  closeIcon = 'chevronUp',
  closeIconProps,
  openIconProps,
  disableCaret,
}: UnstyledAccordionItemCaretProps) => {
  const { toggle, isActive } = useUnstyledAccordionItem(index);

  const props = isActive ? closeIconProps : openIconProps;

  const handleClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
    props?.onClick?.(ev);
    toggle();
  };

  if (disableCaret) return null;

  return (
    <IconButton
      icon={isActive ? closeIcon : openIcon}
      onClick={enableToggle ? handleClick : undefined}
      {...props}
    />
  );
};

export default UnstyledAccordionItemCaret;
