'use client';

import { useEntryIndex } from '@/components/EntryIndex/useEntryIndex';
import IconButton from '@/components/IconButton';
import Txt, { TxtProps } from '@/components/ui/Txt';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { useTranslations } from 'next-intl';
import React from 'react';
import Box, { BoxProps } from '../ui/Box';
import { BtnProps } from '../ui/Btn';
import * as styles from './EntryIndex.css';
import useEntryIndexSubmitButton from './useEntryIndexSubmitButton';

export const useEntryIndexSearchField = (): React.ComponentPropsWithRef<'input'> => {
  const ref = React.useRef<HTMLInputElement>(null);
  const searchValue = useEntryIndex((s) => s.search);
  const [value, setValue] = React.useState<string | null>(null);

  const t = useTranslations('entryIndex');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  React.useEffect(() => {
    setValue(searchValue);
  }, [searchValue]);

  return {
    ref,
    type: 'text',
    placeholder: t('search'),
    onChange: handleChange,
    value: value ?? '',
  };
};

export const useEntryIndexSearchForm = (): {
  formProps: React.ComponentPropsWithRef<'form'>;
  inputProps: React.ComponentPropsWithRef<'input'>;
  searchSummary: string | null;
} => {
  const ref = React.useRef<HTMLFormElement>(null);
  const inputProps = useEntryIndexSearchField();
  const searchSummary = useEntryIndexSearchSummary();
  const setSearchValue = useEntryIndex((s) => s.setSearch);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    console.log('handleSubmit');
    e.preventDefault();
    setSearchValue(inputProps.value as string);
  };

  const handleReset = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSearchValue('');
  };

  return {
    formProps: {
      ref,
      onSubmit: handleSubmit,
      onReset: handleReset,
    },
    inputProps,
    searchSummary,
  };
};

const useEntryIndexSearchSummary = () => {
  const loading = useEntryIndex((s) => s.loading);
  const query = useEntryIndex((s) => s.search);
  const count = useEntryIndex((s) => s.totalItems ?? 0);
  const perPage = useEntryIndex((s) => s.perPage ?? 0);
  const countVisible = Math.min(count, perPage);

  const t = useTranslations('search');

  if (!query) return null;

  if (loading) return t('loading', { query });

  return t('summary', { query, count, countVisible });
};

export const EntryIndexSearchSummary = (props: TxtProps<'div'>) => {
  const text = useEntryIndexSearchSummary();

  return <Txt {...props}>{text}</Txt>;
};

const EntryIndexSearchForm = (props: BoxProps<'form'>) => {
  const { inputProps, /* searchSummary, */ formProps } = useEntryIndexSearchForm();
  const submitButtonProps = useEntryIndexSubmitButton();

  return (
    <Box {...mergePropsClassName(props, styles.searchForm)} as="form" {...formProps}>
      <Box
        className={styles.searchInput}
        as="input"
        colorSet="white"
        paper
        bordered
        rounded
        cx={{ fontSize: 'medium', pX: 'md', pY: 'xs' }}
        placeholder="Search..."
        {...inputProps}
      />
      <Box className={styles.searchButton}>
        <IconButton
          icon="search"
          cx={{ m: 'sm' }}
          {...(submitButtonProps as BtnProps)}
          variant="text"
        />
      </Box>
    </Box>
  );
};

export default EntryIndexSearchForm;
