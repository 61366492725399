'use client';

import UnstyledAccordion from '@/components/UnstyledAccordion';
import { FaqCardFragment } from '__generated__/graphql';
import Box from '../ui/Box';
import Txt from '../ui/Txt';
import * as styles from './FaqCard.css';

const FaqCard = (props: FaqCardFragment) => {
  const index = 1; // Index is irrelevant as there will only be one item

  return (
    <UnstyledAccordion BoxProps={{ cx: { display: 'flex', rowGap: 'sm', direction: 'column' } }}>
      <UnstyledAccordion.Item
        key={props.heading}
        {...{
          index,
          className: styles.accordionItem,
          colorSet: 'primaryTintLighter',
          paper: true,
          HandleProps: {
            index,
          },
          LabelProps: {
            children: props?.heading,
            color: 'primary',
            variant: 'text',
          },
          CaretProps: {
            index,
          },
          BodyProps: {
            index,
            children: (
              <Box cx={{ pX: 'sm' }}>
                <Txt html>{props?.content}</Txt>
              </Box>
            ),
          },
        }}
      />
    </UnstyledAccordion>
  );
};

export default FaqCard;
