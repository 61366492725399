'use client';

import { NavMenuItem } from '@/lib/parsers/navigation';
import React, { useState } from 'react';
import Box, { BoxProps } from '../ui/Box';
import Btn, { BtnProps } from '../ui/Btn';
import Flex from '../ui/Flex';
import * as styles from './NavBar.css';
import { useNavbarActions } from './useNavbarState';
import Txt, { TxtProps } from '../ui/Txt';

type NavItemProps = BoxProps<
  'li',
  {
    item: NavMenuItem;
    TxtProps?: TxtProps;
    BtnProps?: BtnProps;
  }
>;

export default function NavItem({ item, TxtProps, BtnProps, ...props }: NavItemProps) {
  const { title, subItems, href, id } = item;
  const setSearch = useNavbarActions((s) => s.setSearch);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const showSubItems = (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = () => {
    setAnchorEl(null);
    setSearch(false);
  };

  return (
    item && (
      <Box as="li" className={styles.navItem} {...props}>
        <Btn
          variant="nav"
          onMouseEnter={showSubItems}
          onMouseLeave={handleClose}
          onClick={() => setSearch(false)}
          endIcon={!!item.subItems?.length ? 'plus' : undefined}
          {...{ href, id, ...BtnProps }}>
          <Txt {...TxtProps}>{title}</Txt>
        </Btn>
        {!!subItems?.length && open && (
          <Box
            id={id + '_subItem'}
            className={styles.menuWrapper}
            onMouseEnter={showSubItems}
            onMouseLeave={handleClose}>
            <Flex className={styles.menu()}>
              {subItems?.map((subItem) => (
                <Btn
                  variant="nav"
                  key={subItem.id}
                  onClick={handleClick}
                  {...{ href: subItem.href, id: subItem.id, ...BtnProps }}>
                  {subItem.title}
                </Btn>
              ))}
            </Flex>
          </Box>
        )}
      </Box>
    )
  );
}
