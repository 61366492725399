'use client';

import { ENTRY_BASE_FRAGMENT } from '@/gql/fragments/entries.gql';
import { PAGE_OVERVIEW_CARD_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { useColorScheme } from '@/lib/parsers/common';
import { parseUri } from '@liquorice/utils';
import { getFragmentData } from '__generated__';
import Card from '../Card';
import Box from '../ui/Box';
import createCard from './createCard';

const OverviewCard = createCard(PAGE_OVERVIEW_CARD_FRAGMENT, ({ data, overrides, ...props }) => {
  const { entryImage, entryTitle, entrySummary, colorScheme, ...rest } = data;
  const { uri } = getFragmentData(ENTRY_BASE_FRAGMENT, rest);
  const { heading, image: maybeImage } = overrides ?? {};
  const { colorScheme: colorSet, oppositeScheme } = useColorScheme(colorScheme);

  return (
    <Card
      {...props}
      colorSet={colorSet}
      paper
      fullHeight
      elevation={false}
      padding="lg"
      item={{
        title: heading ?? entryTitle,
        description: entrySummary,
        image: maybeImage ?? entryImage,
        href: parseUri(uri),
      }}>
      <Box cx={{ pX: 'sm', pT: 'sm' }}>
        <Card.Image ratio="landscape" />
      </Box>
      <Card.Body>
        <Card.Title variant="h4" cx={{ mB: 'none' }} />
        <Card.Description />
      </Card.Body>
      <Card.Foot>
        <Card.Cta variant="outlined" color={oppositeScheme} rounded="small" />
      </Card.Foot>
    </Card>
  );
});

export default OverviewCard;
