import { ENTRY_CARDS_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { withFragment } from '@liquorice/gql-utils';
import ArticleCard from './ArticleCard';
import EntryEventCard from './EventCard';
import HomeCard from './HomeCard';
import OverviewCard from './OverviewCard';
import StandardCard from './StandardCard';
import VenueCard from './VenueCard';

const EntryCard = withFragment(ENTRY_CARDS_FRAGMENT, (data) => {
  switch (data?.__typename) {
    case 'article_Entry':
      return <ArticleCard data={data} />;
    case 'eventInfo_Entry':
      return null;
    case 'eventPrice_Entry':
      return null;
    case 'eventTime_Entry':
      return null;
    case 'event_Entry':
      return <EntryEventCard data={data} />;
    case 'expandable_Entry':
      return null;
    case 'faq_Entry':
      return null;
    case 'home_Entry':
      return <HomeCard data={data} />;
    case 'index_Entry':
      return null;
    case 'notFound_Entry':
      return null;
    case 'overview_Entry':
      return <OverviewCard data={data} />;
    case 'socialLinks_Entry':
      return null;
    case 'standard_Entry':
      return <StandardCard data={data} />;
    case 'venue_Entry':
      return <VenueCard data={data} />;
    // default:
    //   return assertUnreachable(data);
  }
});

export default EntryCard;
