import {
  LINK_FIELD_CUSTOM_FRAGMENT,
  LINK_FIELD_EMAIL_FRAGMENT,
  LINK_FIELD_ENTRY_FRAGMENT,
  LINK_FIELD_FRAGMENT,
  LINK_FIELD_PHONE_FRAGMENT,
  LINK_FIELD_URL_FRAGMENT,
} from '@/gql/fragments/linkField.gql';
import { deleteUndefined, toString, toStringOrNull } from '@liquorice/allsorts-craftcms-nextjs';
import { createFragmentArrayParser } from '@liquorice/gql-utils';
import { firstNonNullable } from '@liquorice/utils';
import { getFragmentData } from '__generated__';
import { LinkFieldFragment } from '__generated__/graphql';

export interface Link extends Pick<IntrinsicProps<'a'>, 'target' | 'title'> {
  href: string;
  text?: string;
  children?: string;
  download?: boolean;
  //   file?: DocumentAsset;
}

const parseLink = (data: LinkFieldFragment) => {
  const item = firstNonNullable(data);

  let fragment = null;

  if (item?.__typename === 'linkField_Url_LinkType')
    fragment = getFragmentData(LINK_FIELD_URL_FRAGMENT, item);
  if (item?.__typename === 'linkField_Entry_LinkType')
    fragment = getFragmentData(LINK_FIELD_ENTRY_FRAGMENT, item);
  if (item?.__typename === 'linkField_Email_LinkType')
    fragment = getFragmentData(LINK_FIELD_EMAIL_FRAGMENT, item);
  if (item?.__typename === 'linkField_Phone_LinkType')
    fragment = getFragmentData(LINK_FIELD_PHONE_FRAGMENT, item);
  if (item?.__typename === 'linkField_Custom_LinkType')
    fragment = getFragmentData(LINK_FIELD_CUSTOM_FRAGMENT, item);

  if (!fragment) return null;

  const { target, type, text, title, url: href } = fragment;

  const download = type === 'asset';
  const defaultTarget =
    type && ['asset', 'mail', 'url', 'tel'].includes(type) ? '_blank' : undefined;

  const field: Link = {
    href: toString(href),
    target: toStringOrNull(target) ?? defaultTarget,
    children: toStringOrNull(text) ?? undefined,
    title: toStringOrNull(title) ?? undefined,
    download,
  };

  return deleteUndefined(field);
};

export const parseLinkField = createFragmentArrayParser(LINK_FIELD_FRAGMENT, (data) => {
  if (!data) return null;

  const item = firstNonNullable(data);

  if (!item) return null;

  return parseLink(item);
});
