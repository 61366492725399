'use client';

import useScrollPosition from '@/lib/utils/useScrollPosition';
import { useBreakpointMax, useBreakpointMin } from '@/theme';
import { isTypedFragment } from '@liquorice/gql-utils';
import { AnimatePresence, motion } from 'framer-motion';
import Logo from '../Logo';
import NavDrawer from '../NavDrawer';
import Box from '../ui/Box';
import Divider from '../ui/Divider';
import Flex from '../ui/Flex';
import * as navBarTabletStyles from './NavBar.Tablet.css';
import NavIcons from './NavIcons';
import NavInner from './NavInner';
import NavMenu from './NavMenu';
import NavSearch from './NavSearch';
import NavShim from './NavShim';
import { useNavBar } from './useNavBar';

const NavBarTablet = () => {
  const lg = useBreakpointMax('lg');
  const sm = useBreakpointMin('sm');
  const pos = useScrollPosition();

  const { page } = useNavBar();
  const showExtras = isTypedFragment(page, 'event_Entry') ? pos < 10 : false;

  return (
    sm &&
    lg && (
      <Box as="nav">
        <NavShim />
        <NavInner ContainerProps={{ disablePadding: true }}>
          <Flex direction="row" justifyContent="between" cx={{ pX: 'xs', pY: '2xs' }}>
            <NavIcons columnGap="xs" btnSize="large" search={false} />
            <Box className={navBarTabletStyles.logoWrapper}>
              <Logo homeLink SvgProps={{ viewBox: '0 0 160 64' }} />
            </Box>
            <NavIcons columnGap="xs" btnSize="large" shoppingBasket={false} profile={false} menu />
          </Flex>
          <NavDrawer />
          <NavSearch />
          <AnimatePresence>
            {!showExtras && (
              <Box
                as={motion.div}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>
                <Divider cx={{ color: 'primaryTintLight', width: 'full' }} />
                <NavMenu
                  cx={{ justifyContent: 'center', pX: 'xs', pT: '2xs', pB: '3xs' }}
                  BtnProps={{
                    color: 'primary',
                    size: 'small',
                    underline: 'hover',
                    weight: 'semibold',
                    disableTransition: true,
                    className: navBarTabletStyles.navMenu,
                  }}
                  columnGap="3xl"
                />
              </Box>
            )}
          </AnimatePresence>
        </NavInner>
      </Box>
    )
  );
};

export default NavBarTablet;
