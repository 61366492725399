import {
  ARTICLE_CARD_FRAGMENT,
  ARTICLE_INDEX_CARD_FRAGMENT,
  EVENT_CARD_FRAGMENT,
  FAQ_CARD_FRAGMENT,
  HOME_CARD_FRAGMENT,
  PAGE_EXPANDABLE_CARD_FRAGMENT,
  PAGE_OVERVIEW_CARD_FRAGMENT,
  PAGE_STANDARD_CARD_FRAGMENT,
  VENUE_CARD_FRAGMENT,
} from '@/gql/fragments/entryCards.gql';
import { getFragmentData } from '__generated__';
import { EntryCardsFragment } from '__generated__/graphql';

type EntryCards = EntryCardsFragment | null;

export const useEntryCards = (data: EntryCards) => {
  let fragment;
  let typeLabel;

  if (!data) return null;

  switch (data?.__typename) {
    case 'article_Entry':
      fragment = getFragmentData(ARTICLE_CARD_FRAGMENT, data);
      typeLabel = 'Article';
      break;
    case 'event_Entry':
      fragment = getFragmentData(EVENT_CARD_FRAGMENT, data);
      typeLabel = 'Event';
      break;
    case 'expandable_Entry':
      fragment = getFragmentData(PAGE_EXPANDABLE_CARD_FRAGMENT, data);
      typeLabel = 'Expandable';
      break;
    case 'home_Entry':
      fragment = getFragmentData(HOME_CARD_FRAGMENT, data);
      typeLabel = 'Home';
      break;
    case 'index_Entry':
      fragment = getFragmentData(ARTICLE_INDEX_CARD_FRAGMENT, data);
      typeLabel = 'Index';
      break;
    case 'overview_Entry':
      fragment = getFragmentData(PAGE_OVERVIEW_CARD_FRAGMENT, data);
      typeLabel = 'Overview';
      break;
    case 'standard_Entry':
      fragment = getFragmentData(PAGE_STANDARD_CARD_FRAGMENT, data);
      typeLabel = 'Standard';
      break;
    case 'venue_Entry':
      fragment = getFragmentData(VENUE_CARD_FRAGMENT, data);
      typeLabel = 'Venue';
      break;
    case 'faq_Entry':
      fragment = getFragmentData(FAQ_CARD_FRAGMENT, data);
      typeLabel = 'FAQ';
      break;
    default:
      break;
  }

  return { fragment, typeLabel };
};

export type EntryCardsReturnType = ReturnType<typeof useEntryCards>;
