import { DOCUMENT_FRAGMENT } from '@/gql/fragments/document.gql';
import {
  deleteUndefined,
  firstNonNullable,
  humanFileSize,
  makeNonNullableArray,
  toStringOrNull,
} from '@liquorice/allsorts-craftcms-nextjs';
import { FragmentType, getFragmentData } from '__generated__';

export type DocumentAsset = NonNullable<ReturnType<typeof parseDocument>>;

export type MaskedDocument = FragmentType<typeof DOCUMENT_FRAGMENT>;

export const parseDocument = (maybeElement?: MaybeArrayOf<MaskedDocument>) => {
  const maybeDoc = firstNonNullable(maybeElement);
  const doc = getFragmentData(DOCUMENT_FRAGMENT, maybeDoc);

  if (!doc) return null;
  const { size, ...rest } = doc;

  // const summary = toStringOrNull(maybeGet(doc, 'summary')) ?? undefined;
  const title = toStringOrNull(doc.title) ?? undefined;
  const id = toStringOrNull(doc.id) ?? undefined;

  return deleteUndefined({
    ...rest,
    id,
    title,
    /* summary, */
    size,
    sizeHuman: humanFileSize(size),
  });
};

export const parseDocuments = (maybeElements?: MaybeArrayOf<MaskedDocument>) => {
  return makeNonNullableArray(maybeElements).reduce((results, item) => {
    const asset = parseDocument(item);
    if (asset) results.push(asset);
    return results;
  }, [] as DocumentAsset[]);
};
