import { gql } from '__generated__';

export const DOCUMENT_FRAGMENT = gql(`
  fragment document on AssetInterface {
    mimeType
    id
    alt
    title
    __typename
    url
    filename
    extension
    size
  }
`);
