import React, { ForwardedRef } from 'react';
import Btn, { BtnComponentWithRef } from '../ui/Btn';

// export type CtaButtonProps = Link;

const CtaButton: BtnComponentWithRef = React.forwardRef(function CtaButton(
  props,
  // TODO: Fix the eslint-disable-next-line comment
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: ForwardedRef<any>
) {
  return <Btn ref={ref} variant="text" weight="bold" endIcon="chevronRight" {...props} />;
});

export default CtaButton;
