'use client';

import { ENTRY_BASE_FRAGMENT } from '@/gql/fragments/entries.gql';
import { HOME_CARD_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { parseUri } from '@liquorice/utils';
import { getFragmentData } from '__generated__';
import Card from '../Card';
import Box from '../ui/Box';
import createCard from './createCard';

const HomeCard = createCard(HOME_CARD_FRAGMENT, ({ data, overrides, ...props }) => {
  const { entryImage, entryTitle, entrySummary: description, ...base } = data;
  const { uri, title } = getFragmentData(ENTRY_BASE_FRAGMENT, base);
  const { heading, image: maybeImage } = overrides ?? {};

  const image = maybeImage ?? entryImage;
  const href = parseUri(uri);

  return (
    <Card
      {...props}
      paper={false}
      fullHeight
      elevation={false}
      padding="md"
      item={{
        title: heading ?? entryTitle ?? title,
        description,
        image,
        href,
        // customText: ExtraProps?.customText,
      }}>
      <Box>
        <Card.Image ratio="landscape" />
      </Box>
      <Card.Body>
        <Card.Title variant="h4" color="primary" cx={{ mB: 'none', fontFamily: 'mono' }} />
        <Card.Description color="primary" />
      </Card.Body>
      <Card.Foot>
        <Card.Cta variant="outlined" size="normal" fullWidth cx={{ fontSize: 'medium' }} />
      </Card.Foot>
    </Card>
  );
});

export default HomeCard;
