'use client';

import NavBarMobile from './NavBar.Mobile';
import { NavBase } from './useNavBar';
import NavBarContext from './NavBarContext';
import { combineComponentChildren } from '../ui/Polymorphic';
import NavInner from './NavInner';
import NavIcons from './NavIcons';
import NavLogo from './NavLogo';
import NavMenu from './NavMenu';
import NavSearch from './NavSearch';
import NavDrawer from '../NavDrawer';
import NavShim from './NavShim';
import NavBarTablet from './NavBar.Tablet';
import NavBarDesktop from './NavBar.Desktop';

export const NavBarLayout = (props: NavBase) => {
  return (
    <NavBarContext {...props}>
      <NavBarMobile />
      <NavBarTablet />
      <NavBarDesktop />
    </NavBarContext>
  );
};

const Nav = combineComponentChildren(NavBarLayout, {
  Inner: NavInner,
  Icons: NavIcons,
  Logo: NavLogo,
  Menu: NavMenu,
  Search: NavSearch,
  Drawer: NavDrawer,
  Shim: NavShim,
});

export default Nav;
