import { gql } from '__generated__';

export const LINK_FIELD_URL_FRAGMENT = gql(`
  fragment linkFieldUrl on linkField_Url_LinkType {
    __typename
    target
    text
    newWindow
    title
    type
    url
  }
`);

export const LINK_FIELD_ENTRY_FRAGMENT = gql(`
  fragment linkFieldEntry on linkField_Entry_LinkType {
    __typename
    target
    text
    newWindow
    title
    type
    url
  }
`);

export const LINK_FIELD_CUSTOM_FRAGMENT = gql(`
  fragment linkFieldCustom on linkField_Custom_LinkType {
    __typename
    target
    text
    newWindow
    title
    type
    url
  }
`);

export const LINK_FIELD_EMAIL_FRAGMENT = gql(`
  fragment linkFieldEmail on linkField_Email_LinkType {
    __typename
    target
    text
    newWindow
    title
    type
    url
  }
`);

export const LINK_FIELD_PHONE_FRAGMENT = gql(`
  fragment linkFieldPhone on linkField_Phone_LinkType {
    __typename
    target
    text
    newWindow
    title
    type
    url
  }
`);

export const LINK_FIELD_FRAGMENT = gql(`
  fragment linkField on HyperLinkInterface {
    ...linkFieldUrl
    ...linkFieldEntry
    ...linkFieldCustom
    ...linkFieldEmail
    ...linkFieldPhone
  }
`);
