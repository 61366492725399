'use client';

import { PossibleEntryCardFragments, PossibleEntryFragments } from '@/lib/parsers/entries';
import { AppGlobals } from '@/lib/parsers/globals';
import { NavMenuItem } from '@/lib/parsers/navigation';
import React from 'react';

export interface NavBase {
  primaryItems?: NavMenuItem[];
  secondaryItems?: NavMenuItem[];
  profile?: AppGlobals['profile'];
  shoppingBasket?: AppGlobals['shoppingBasket'];
  page?: PossibleEntryCardFragments;
}

const navBarDefaultState: NavBase = {};

export const createNavBarContext = <T extends NavBase>(defaultState: T) => {
  const NavBarContext = React.createContext<T>(defaultState);
  const NavBarProvider = NavBarContext.Provider;
  const useNavBar = () => React.useContext(NavBarContext);
  return {
    NavBarContext,
    NavBarProvider,
    useNavBar,
  };
};

export const { NavBarProvider, useNavBar } = createNavBarContext(navBarDefaultState);
