'use client';

import { vars } from '@/theme/vars.css';
import { AnimatePresence, motion } from 'framer-motion';
import SearchForm from '../SearchForm';
import * as styles from './NavBar.css';
import { useNavbarActions } from './useNavbarState';
import Container from '../ui/Container';
import Flex from '../ui/Flex';

const NavSearch = () => {
  const search = useNavbarActions((s) => s.search);

  return (
    <AnimatePresence>
      {search && (
        <Flex
          as={motion.div}
          colorSet="white"
          paper
          justifyContent="center"
          className={styles.searchWrapper}
          initial={{ opacity: 0, x: 15 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0 }}>
          <Container>
            <SearchForm
              SearchBtnProps={{ color: 'primary', variant: 'icon' }}
              CloseBtnProps={{ color: 'primary', variant: 'text' }}
              TextFieldProps={{
                InputProps: {
                  disableUnderline: true,
                  sx: {
                    fontSize: vars.typography.size.h5,
                    color: vars.palette.colorSets.primary.main,
                  },
                },
              }}
            />
          </Container>
        </Flex>
      )}
    </AnimatePresence>
  );
};

export default NavSearch;
