'use client';

import { NavMenuItem } from '@/lib/parsers/navigation';
import { mergePropsClassName } from '@liquorice/utils';
import { BoxProps } from '../ui/Box';
import { BtnProps } from '../ui/Btn';
import Flex, { FlexProps } from '../ui/Flex';
import { TxtProps } from '../ui/Txt';
import * as styles from './NavBar.css';
import NavItem from './NavItem';
import { useNavbarActions } from './useNavbarState';
import { useNavBar } from './useNavBar';

export type NavMenu = FlexProps<
  'ul',
  {
    items?: NavMenuItem[];
    ItemProps?: BoxProps<'li'>;
    TxtProps?: TxtProps;
    BtnProps?: BtnProps;
  }
>;

export default function NavMenu({ items, ItemProps, TxtProps, BtnProps, ...props }: NavMenu) {
  const { primaryItems } = useNavBar();
  const withColorSet = useNavbarActions((s) => s.posColorSet);
  const customBtnProps = mergePropsClassName(BtnProps, styles.navMenu({ withColorSet }));

  return (
    <Flex as="ul" direction="row" alignItems="center" columnGap="gutter" {...props}>
      {(items || primaryItems)?.map((item) => {
        return (
          <NavItem key={item.id} {...{ item, TxtProps, BtnProps: customBtnProps, ...ItemProps }} />
        );
      })}
    </Flex>
  );
}
