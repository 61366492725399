'use client';

import { mergePropsClassName } from '@liquorice/utils';
import { useTranslations } from 'next-intl';
import CtaButton from '../CtaButton';
import { useNavbarActions } from '../NavBar/useNavbarState';
import { BtnProps } from '../ui/Btn';
import Txt from '../ui/Txt';
import * as styles from './NavDrawer.css';

export default function NavDrawerButton(props: BtnProps) {
  const t = useTranslations('actions');
  const withColorSet = useNavbarActions((s) => s.posColorSet);
  const setNavPosition = useNavbarActions((s) => s.setNavPosition);

  return (
    <CtaButton
      disableTransition
      onClick={() => setNavPosition(true)}
      EndIconProps={{ name: 'menu', className: styles.icon }}
      {...mergePropsClassName(props, styles.menu({ withColorSet }))}>
      <Txt weight="regular" cx={{ fontSize: 'tiny', fontFamily: 'mono' }}>
        {t('menu').toUpperCase()}
      </Txt>
    </CtaButton>
  );
}
