'use client';

import { mergePropsClassName } from '@liquorice/utils';
import Flex, { FlexProps } from '../ui/Flex';
import * as styles from './UnstyledAccordion.css';
import { useUnstyledAccordionItem } from './useUnstyledAccordion';

export type UnstyledAccordionItemHandleProps<C extends React.ElementType = 'div'> = FlexProps<
  C,
  {
    index?: number;
    disableHandleLink?: boolean;
  }
>;

const UnstyledAccordionItemHandle = ({
  index,
  children,
  disableHandleLink,
  ...props
}: UnstyledAccordionItemHandleProps) => {
  const { toggle } = useUnstyledAccordionItem(index);

  const handleClick = (ev: React.MouseEvent<HTMLDivElement>) => {
    props?.onClick?.(ev);
    toggle();
  };

  return (
    <Flex
      role="button"
      justifyContent="between"
      alignItems="center"
      rowGap="gutter"
      columnGap="gutter"
      direction="row"
      cx={{ pY: 'md', pX: 'sm' }}
      {...mergePropsClassName(props, styles.control)}
      onClick={disableHandleLink ? undefined : handleClick}>
      {children}
    </Flex>
  );
};

export default UnstyledAccordionItemHandle;
