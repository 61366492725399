'use client';

import { useBreakpointMin } from '@/theme';
import NavDrawer from '../NavDrawer';
import NavDrawerButton from '../NavDrawer/NavDrawer.Btn';
import Box from '../ui/Box';
import Flex from '../ui/Flex';
import NavIcons from './NavIcons';
import NavInner from './NavInner';
import NavLogo from './NavLogo';
import NavMenu from './NavMenu';
import NavSearch from './NavSearch';
import NavShim from './NavShim';

const NavBarDesktop = () => {
  const lg = useBreakpointMin('lg');

  return (
    lg && (
      <Box as="nav">
        <NavShim />
        <NavInner ContainerProps={{ disablePadding: true }}>
          <Flex
            direction="row"
            justifyContent="between"
            alignItems="end"
            cx={{ pB: 'md', pX: 'md' }}>
            <NavLogo />
            <NavMenu
              BtnProps={{
                color: 'primary',
                underline: 'hover',
                weight: 'semibold',
                disableTransition: true,
              }}
              columnGap="3xl"
            />
            <Flex direction="column" alignItems="end" rowGap="5xs">
              <NavIcons columnGap="xs" />
              <NavDrawerButton />
            </Flex>
          </Flex>
          <NavDrawer />
          <NavSearch />
        </NavInner>
      </Box>
    )
  );
};

export default NavBarDesktop;
