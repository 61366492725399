'use client';

import { useBreakpointMax } from '@/theme';
import Box from '../ui/Box';
import Txt, { TxtProps } from '../ui/Txt';
import * as styles from './Footer.css';

type FooterCompanyInformationProps = TxtProps<
  'span',
  {
    label?: string | null;
    enableMobile?: boolean;
  }
>;

export default function FooterCompanyInformation({
  label,
  enableMobile,
  ...props
}: FooterCompanyInformationProps) {
  const md = useBreakpointMax('md');

  const inner = label && (
    <Box colorSet="white">
      <Txt html {...props} className={styles.companyInformation}>
        {label}
      </Txt>
    </Box>
  );

  if (enableMobile) {
    return md ? inner : null;
  }

  if (!enableMobile) {
    return md ? null : inner;
  }
}
