'use client';

import React from 'react';
import { NavBarProvider, NavBase } from './useNavBar';

type NavBarContextProps = React.PropsWithChildren<NavBase>;

export default function NavBarContext({ children, ...props }: NavBarContextProps) {
  return <NavBarProvider value={props}>{children}</NavBarProvider>;
}
