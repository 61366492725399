import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import React from 'react';
import Btn, { BtnComponent, BtnProps } from '../ui/Btn';
import * as styles from './UnstyledAccordion.css';

export type UnstyledAccordionItemLabelProps<D extends React.ElementType = 'button'> = BtnProps<D>;

const UnstyledAccordionItemLabel: BtnComponent = <D extends React.ElementType>({
  children,
  ...props
}: UnstyledAccordionItemLabelProps<D>) => {
  return (
    <Btn variant="unstyled" wrap {...mergePropsClassName(props, styles.itemLabel)}>
      {children}
    </Btn>
  );
};

export default UnstyledAccordionItemLabel;
