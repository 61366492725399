'use client';

import { TypedDocumentNode } from '@apollo/client';
import { FragmentType, getFragmentData } from '__generated__';
import { MaybeImage } from '../Image';
import { BoxProps } from '../ui/Box';

type OverrideCardProps = {
  heading?: string | null;
  content?: string | null;
  image?: MaybeImage;
};

type CardProps<T> = {
  id: string;
  data: T;
  overrides?: OverrideCardProps;
};

export type MaskedCardProps<T, D extends React.ElementType = 'article'> = BoxProps<
  D,
  {
    data: FragmentType<TypedDocumentNode<T>>;
  }
>;

export type MaskedCardWithOverridesProps<T> = MaskedCardProps<T> & {
  overrides?: OverrideCardProps;
};

const createCard = <TType, R extends JSX.Element | null>(
  fragment: TypedDocumentNode<TType>,
  CardComponent: (props: CardProps<TType>) => R
) => {
  function EntryCard<T extends TType>(props: MaskedCardWithOverridesProps<T>) {
    const { data: maybeFragmentData, overrides, ...rest } = props;

    const data = getFragmentData(fragment, maybeFragmentData) ?? null;

    if (!data) return null;

    return (
      <CardComponent
        {...({
          data,
          overrides,
          ...rest,
        } as CardProps<TType>)}
      />
    );
  }

  return EntryCard;
};

export default createCard;
