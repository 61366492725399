'use client';

import { PAGE_STANDARD_CARD_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import BasicCard from '../BasicCard';
import { CardBase } from '../Card/useCard';
import createCard from './createCard';

const StandardCard = createCard(PAGE_STANDARD_CARD_FRAGMENT, ({ data, overrides, ...props }) => {
  const { entryImage, entryTitle, entrySummary } = data;
  const { heading, image: maybeImage } = overrides ?? {};
  // const href = parseUri(uri);

  const item: CardBase = {
    title: heading ?? entryTitle,
    description: entrySummary,
    image: maybeImage ?? entryImage,
    // href,
  };

  return <BasicCard item={item} {...props} />;
});

export default StandardCard;
