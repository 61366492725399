import Card, { CardProps } from '../Card';
import Box from '../ui/Box';

export default function BasicCard(props: CardProps) {
  return (
    <Card elevation={false} padding="sm" {...props}>
      <Box>
        <Card.Image ratio="landscape" />
      </Box>
      <Card.Body>
        <Card.Title variant="h5" />
        <Card.Description />
      </Card.Body>
      <Card.Foot>
        <Card.Cta />
      </Card.Foot>
    </Card>
  );
}
